import clsx from 'clsx'
import { useEffect, useState } from 'react'
import { createRoot } from 'react-dom/client'
import Button from '../button'
import './alert.scss'
import icError from './ic-error.svg'
import icSuccess from './ic-success.svg'
import icWarning from './ic-warning.svg'

let alertContainer = null
let alertRoot = null

const ModalAlert = ({
    icon,
    title,
    message,
    buttonCloseText,
    buttonConfirmText,
    autoClose,
    backdrop,
    onClose,
    interval,
}) => {
    const [show, setShow] = useState(true)
    useEffect(() => {
        if (autoClose) {
            const timer = setTimeout(() => {
                setShow(false)
                onClose(false)
            }, interval)

            return () => clearTimeout(timer);
        }
    }, [onClose, autoClose])

    const handleOnHide = (e) => {
        e.stopPropagation()
        if (!backdrop) return
        setShow(false)
    }

    const handleConfirm = () => {
        setShow(false)
        onClose(true)
    };

    const handleCancel = () => {
        setShow(false)
        onClose(false)
    }

    const renderIcon = (value) => {
        switch (value) {
            case 'success':
                return icSuccess
            case 'error':
                return icError
            case 'warning':
                return icWarning
            default:
                return value
        }
    }

    return (
        <div className={clsx("avo__alert", show ? "show" : "hide")} onClick={handleOnHide} >
            {show && <div className={clsx("dialog", show ? "show" : 'hide')}>
                <div className="content" onClick={(e) => e.stopPropagation()}>
                    {icon && <img src={renderIcon(icon)} alt="alert" />}
                    {title && <span className="title">{title}</span>}
                    {message && <span className={clsx("message", (!buttonCloseText && !buttonConfirmText) && "no-footer")}>{message}</span>}
                    {(buttonCloseText || buttonConfirmText) && <div className="footer">
                        {buttonCloseText && <Button type="outline" title={buttonCloseText} onClick={handleCancel} />}
                        {buttonConfirmText && <Button title={buttonConfirmText} onClick={handleConfirm} />}
                    </div>}
                </div>
            </div>}
        </div >
    )
}

const Alert = (options) => {

    if (alertContainer) {
        alertRoot.unmount()
        alertContainer.remove()
    }

    alertContainer = document.createElement('div')
    document.body.appendChild(alertContainer)

    return new Promise((resolve) => {
        const { icon, title, message, buttonCloseText, buttonConfirmText, backdrop = true, autoClose = false, interval = 2000 } = options

        const closeAlert = (result) => {
            alertRoot.unmount();
            alertContainer.remove();
            resolve(result);
        }

        const props = {
            icon,
            title,
            message,
            buttonCloseText,
            buttonConfirmText,
            backdrop,
            autoClose,
            onClose: closeAlert,
            interval,
        }

        alertRoot = createRoot(alertContainer)
        alertRoot.render(<ModalAlert {...props} />);

    })
}

export { Alert }
