import clsx from "clsx"
import './button.scss'

const Button = ({ className, title, onClick, disabled, loading, type = "primary", size, shape, icon, iconAfter }) => {

    const handleOnClick = () => {
        if (!onClick || loading) return
        onClick()
    }

    return (
        <button
            className={clsx("avo__button", type, size, shape, loading && "btn-loading", !title && "btn-no-text", className)}
            type="button"
            disabled={disabled}
            onClick={handleOnClick}>
            <div className={clsx("btn-content", loading && "btn-content-loading")}>
                {icon && <img src={icon} alt={title} />}
                {title}
                {iconAfter && <img src={iconAfter} alt={title} />}
            </div>
        </button>
    )
}

export default Button
