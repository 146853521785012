import axios, {createInstance} from "../libs/axios"
import {useEffect, useReducer} from "react"
import {METHOD} from "./method"
import {API} from "./api";
import bcrypt from "bcryptjs/dist/bcrypt"
import CONFIG from "../config/env";
import {Alert} from "../components/commons/alert";

const initialData = {
    isLoading: false,
    data: null,
    error: null,
}

const ACTION_TYPE_RESPONSE = {
    SUCCESS: 'SUCCESS',
    LOADING: 'LOADING',
    ERROR: 'ERROR'
}

const responseReducer = (state, action) => {
    switch (action.type) {
        case ACTION_TYPE_RESPONSE.SUCCESS:
            return {
                isLoading: false,
                data: action.payload,
                error: null,
            }
        case ACTION_TYPE_RESPONSE.LOADING:
            return {
                ...state,
                isLoading: true,
            }
        case ACTION_TYPE_RESPONSE.ERROR:
            return {
                isLoading: false,
                data: null,
                error: action.payload,
            }
        default:
            return state
    }
}
const defaultOptions = {
    isFetching: false,
    isLoading: false,
}

export const useFetch = ({api, method, initPathVariable}, initPayload, options = defaultOptions) => {
    const [data, dispatchResponse] = useReducer(responseReducer, {...initialData, isLoading: options.isLoading})
    let attempt = 0

    useEffect(() => {
        if (options.isFetching) fetching()
    }, [])

    const fetching = async (payload, pathVariable, header, responseType, additionalQueryParams = {}) => {
        dispatchResponse({type: ACTION_TYPE_RESPONSE.LOADING})

        const getClientToken = async (sessionId) => {
            localStorage.setItem('sessionId', sessionId)
            try {
                const axiosInstance = createInstance()
                const hashed = await bcrypt.hash(process.env.REACT_APP_PUBLIC_KEY, 10)
                await axiosInstance.post(API.GENERATE_SESSION.api, {sigAuthId: hashed, identifier: sessionId})
            } catch (error) {
                console.log('ERR generate session', error)
            }
        }

        const byPassToken = async () => {
            try {
                const axiosInstance = createInstance()
                await axiosInstance.post(API.BYPASS_SESSION.api, {})
            } catch (error) {
                console.log('ERR generate session', error)
            }
        }

        const redirectUserAuth = async () => {
            if (CONFIG.ENV === 'DEV') {
                await byPassToken()
            } else {
                const location = window.location.href.split('/')
                const sessionId = location[3].split('=')
                if (sessionId?.length === 1) {
                    window.location.href =  `${CONFIG.API_ENDPOINT}${API.LOGOUT.api}`
                } else {
                    await getClientToken(sessionId[1])
                }
            }
        }

        const handlingError = (response) => {
            console.log('ERROR: ', response?.data?.message || 'Terjadi Kesalahan')
            dispatchResponse({type: ACTION_TYPE_RESPONSE.ERROR, payload: response?.data})
        };

        const request = async () => {
            const requestKey = (method === METHOD.GET) ? 'params' : 'data'
            try {
                const response = await axios({
                    url: `${api}${initPathVariable || pathVariable ? ('/' + (initPathVariable || pathVariable)) : ''}`,
                    method: method,
                    [requestKey]: {
                        ...initPayload,
                        ...payload
                    },
                    [Object.keys(additionalQueryParams).length > 0 ? 'params' : null]: {
                        ...additionalQueryParams
                    },
                    headers: {
                        ...header,
                    },
                    responseType
                })
                dispatchResponse({type: ACTION_TYPE_RESPONSE.SUCCESS, payload: response.data.data})
                return response.data
            } catch (error) {
                if (attempt === 2) {
                    handlingError(error?.response);
                    throw error?.response?.data;
                }
                switch (error?.response?.status) {
                    case 401:
                        switch (error.response.data.code) {
                            case '401-A002':
                            case '401-A003':
                            case '401-A004':
                            case '401-A005':
                                if (CONFIG.ENV === 'DEV') {
                                    await byPassToken()
                                } else {
                                    Alert({
                                        icon: 'error',
                                        title: 'Authentication Error !',
                                        message: error.response.data.code === '401-A005' ? 'Akun Anda Ditangguhkan' : 'Anda Tidak Memiliki Akses',
                                        buttonConfirmText: 'OK',
                                        autoClose: false,
                                        backdrop: false
                                    }).then((isConfirm) => {
                                        console.log("isConfirm :", isConfirm)
                                        window.location.href = `${CONFIG.API_ENDPOINT}/v1/auth/login`
                                    })
                                }
                                break
                            default:
                                await redirectUserAuth()
                                break;
                        }
                        break;
                    default:
                        break;
                }

                console.log('error?.response', error)
                handlingError(error?.response);
                throw error?.response?.data;
            }
        }
        return await request()
    }

    return {data, fetching, isLoading: data.isLoading}
}
