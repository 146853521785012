import {BrowserRouter} from "react-router-dom";
import RoutePage from "./routes";

function App() {
    return (
        <BrowserRouter>
            <RoutePage/>
        </BrowserRouter>
    );
}

export default App;
