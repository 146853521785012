import {METHOD} from "./method";

export const API = {
    GENERATE_SESSION: {
        api: '/v1/auth/register-session',
        method: METHOD.POST
    },
    BYPASS_SESSION: {
        api: '/v1/auth/manual-callback',
        method: METHOD.POST
    },
    HOME_MENU: {
        api: '/v1/home',
        method: METHOD.GET
    },
    LOGOUT: {
        api: '/v1/auth/logout',
        method: METHOD.POST
    }
}
