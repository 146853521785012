import hrIcon from '../../assets/images/hr-ic.jpg'
import financeIcon from '../../assets/images/finance-ic.png'
import salesIcon from '../../assets/images/sales-ic.jpg'
import logoutIcon from '../../assets/images/ic-logout.svg'
import profileIcon from '../../assets/images/user-default@3x.png'
import {useEffect, useState} from "react";
import dayjs from "dayjs";
import CONFIG from "../../config/env";

export const Home = ({userDetail, onLogoutHandler}) => {
    const {employee, company, available_modules} = userDetail
    const [clock, setClock] = useState(dayjs().format('HH:mm'))
    const [greet, setGreet] = useState(dayjs().format('HH'))
    const [date, setDate] = useState(dayjs().format('dddd, DD MMMM YYYY'))

    useEffect(() => {
        const timerId = setInterval(() => {
            setClock(dayjs().format('HH:mm'))
        }, 1000);
        return function cleanup() {
            clearInterval(timerId);
        };
    }, []);

    const getGreetingText = () => {
        switch (true) {
            case greet > 20:
                return 'Good Night'
            case greet > 16:
                return 'Good Evening'
            case greet > 11:
                return 'Good Afternoon'
            default:
                return 'Good Morning'
        }
    }

    const getIcon = (initial) => {
        switch (initial) {
            case 'hr':
                return hrIcon
            case 'finance':
                return financeIcon
            case 'sales':
                return salesIcon
            default:
                return null
        }
    }

    const onClickHandler = (url) => {
        if (CONFIG.ENV === 'DEV') {
            window.open(`http://localhost:3001`, '_blank', 'noopener,noreferrer')
        } else {
            window.open(`${url}`, '_blank', 'noopener,noreferrer')
        }
    }

    return (
        <div className='min-h-screen w-full px-20'>
            <div className={'flex flex-row items-center justify-between my-12'}>
                <div className={'flex flex-row items-center gap-2'}>
                    <img src={company?.logo} className='w-16 h-16' alt="logo"/>
                    <p className='font-bold text-lg text-start text-[#07506D]'>{company?.name}</p>
                </div>
                <div className={'flex flex-row items-center justify-end gap-5'}>
                    <img src={employee?.profile_picture ? employee.profile_picture : profileIcon} className='w-12 border p-1 rounded-full' alt="img-profile"/>
                    <div className={'text-start'}>
                        <p className={'font-bold'}>{employee?.full_name}</p>
                        <p>{employee?.title}</p>
                    </div>
                    <div
                        onClick={onLogoutHandler}
                        className="p-2.5 bg-white cursor-pointer rounded-lg shadow border border-slate-100 justify-center items-center gap-2 inline-flex">
                        <img src={logoutIcon} alt={'ic-logout'}/>
                    </div>
                </div>
            </div>
            <div
                className={'w-full h-[70vh] flex flex-col gap-2 items-center justify-center text-[#07506D]'}>
                <div><h2 className="text-2xl text-center font-extralight">{getGreetingText()}</h2></div>
                <h2 className="text-8xl text-center font-bold ">{clock}</h2>
                <h2 className="text-xl text-center">{date}</h2>
                <div className={'flex items-center justify-center gap-6 my-8'}>
                    {
                        available_modules?.map((module, index) => (
                            <div
                                key={index}
                                onClick={() => onClickHandler(module?.url)}
                                className={'border shadow-inner flex flex-col items-center justify-center gap-4 p-4 rounded-xl cursor-pointer'}>
                                <img src={getIcon(module?.initial)} className='w-1/2' alt="img-hr"/>
                                <p className={'font-bold'}>{module?.name}</p>
                            </div>
                        ))
                    }
                </div>
            </div>
        </div>
    )
}
