import {Home} from "../../components/home/Home";
import {useEffect, useState} from "react";
import {useFetch} from "../../services/useFetch";
import {API} from "../../services/api";
import CONFIG from "../../config/env";

export const HomeContainer = () => {
    const {fetching: getHomeDataApi} = useFetch(API.HOME_MENU)
    const [userDetail, setUserDetail] = useState()

    useEffect(() => {
        getHomeDataApi()
            .then(resp => setUserDetail(resp?.data))
            .catch(err => console.log("err :", err))
    }, []);

    const onLogoutHandler = () => {
        localStorage.clear()
        window.location.href = `${CONFIG.API_ENDPOINT}${API.LOGOUT.api}`
    }

    const props = {
        userDetail,
        onLogoutHandler
    }

    return !!userDetail?.employee && <Home {...props}/>
}
