import {Route, Routes, useLocation, useNavigate} from "react-router-dom";
import {HomeContainer} from "../containers/home/HomeContainer";
import {useEffect} from "react";

export default function RoutePage() {
    const navigate = useNavigate()
    const location = useLocation()
    const sessionId = location.search.split("=")

    useEffect(() => {
        if (sessionId?.length === 2) {
            localStorage.setItem('sessionId', sessionId[1])
            navigate('/', {replace: true})
        }
    }, []);

    return (
        <Routes>
            <Route path='/' element={<HomeContainer/>}/>
        </Routes>
    )
}
